<template>
  <div>
    <!-- {{currentItem}} -->
    <v-form @submit.prevent="onBeforeSubmit" ref="formRef">
      <v-row dense class="pt-4 pb-0 mb-0">
        <div class="center-text h1"> Procedimento: {{ currentItem.procedimento.procedimento }} {{ currentItem.procedimento.nomecomercial ? ` - "${currentItem.procedimento.nomecomercial}" `  : '' }} - Cód.: {{currentItem.procedimento.codigo}} - "Id: {{ currentItem.id }}"
        </div>
      </v-row>
      <v-row dense class="pt-4 pb-0 mb-0">
        <div class="center-text h1"> <span v-if="isTimestampValid">Data da Evolução: {{ formatTimestamp() }}</span> </div>
      </v-row>
      <v-row dense>
        <v-col>
          <v-select outlined required label="Status" v-model="currentItem.ativo" item-value="value"
            item-text="nome" :items="ativoOptions"></v-select>
        </v-col>
        <v-col v-if="currentItem.aditivo">
          <v-select outlined label="Aditivo" v-model="currentItem.aditivo" item-value="value" disabled
          item-text="nome" :items="statusOptions"></v-select> 
        </v-col>
         <v-col v-if="currentItem.aditivo">
          <v-text-field label="Nrº Aditivo" outlined v-model="currentItem.nr_aditivo" type="Number"  disabled>
          </v-text-field>
        </v-col>
        <v-col v-if="currentItem.aditivo" >
          <v-text-field label="Data Início Aditivo" outlined v-model="currentItem.data_aditivo" type="Date"  disabled>
          </v-text-field>
        </v-col>
        <v-col>
          <v-select outlined label="Frequência" v-model="currentItem.frequencia" :items="frequencies"
          item-value="id" item-text="nome" required :rules="requiredField" ></v-select>
        </v-col>
        <v-col>
          <v-select outlined required label="Cobrança operadora" v-model="currentItem.cobranca" item-value="value"
          item-text="nome" :items="statusOptions"></v-select>
        </v-col>
        <v-col v-if="currentItem.procedimento.especial">
          <v-text-field label="Data Início Execução" outlined v-model="currentItem.data_inicio_especial" type="date" @input="updateSpecialQuantity()">
          </v-text-field>
        </v-col>
        <v-col v-if="currentItem.procedimento.especial">
          <v-text-field label="Data Fim Execução" outlined v-model="currentItem.data_fim_especial" type="date"  @input="updateSpecialQuantity()">
          </v-text-field>
        </v-col>
        <!-- <v-col v-if="currentItem.procedimento.especial">
          <v-text-field label="Data Execução" outlined v-model="currentItem.data_especial" type="date" >
          </v-text-field>
        </v-col> -->
        <v-col v-if="currentItem.procedimento.especial">
          <v-text-field label="Qtd." outlined v-model="currentItem.quantidade" type="Number" disabled >
          </v-text-field>
        </v-col>
        <v-col v-if="!currentItem.procedimento.especial">
          <v-select outlined required label="Offline" v-model="currentItem.offline" item-value="value"
            item-text="nome" :items="statusOptions"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <vuetify-money outlined label="Valor cobrança" v-model="oldUnitPrice" :options="options" :rules="requiredField">
          </vuetify-money>
        </v-col>
        <v-col>
          <v-text-field label="Desconto" outlined v-model.lazy="currentItem.desconto" v-money="currencyMask">
            <template v-slot:append>%</template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Acréscimo" outlined v-model.lazy="currentItem.acrescimo" v-money="currencyMask">
            <template v-slot:append>%</template>
          </v-text-field>
        </v-col>
         <v-col>
          <v-text-field label="Valor Cobrança Ajustado" outlined v-model="procedureTotalValue" disabled>
            <template v-slot:prepend-inner>
              <span style="position: relative; top: 2.5px">R$</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-select outlined required label="Pagamento prestador" v-model="currentItem.pagamento" item-value="value"
          item-text="nome" :items="statusOptions"></v-select>
        </v-col>
        <v-col>
          <vuetify-money outlined label="Valor pagamento" v-model="currentItem.valor_pagamento" :options="options" :rules="requiredField">
          </vuetify-money>
        </v-col>
        <v-col >
          <v-select outlined required label="Recurso Glosa" v-model="currentItem.recurso_glosa" item-value="value"
            item-text="nome" :items="statusOptions"></v-select>
        </v-col>
      </v-row>
      <v-row dense >
        <v-col v-if="currentItem.recurso_glosa">
          <v-text-field label="Justificativa da Glosa " outlined v-model="currentItem.justificativarecursoglosa"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-end">
          <ConfirmButton color="success" :loading="loading">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
  
<script>
import ConfirmButton from '../../ConfirmButton.vue';
import UtilsFunc from '../../../service/utilsFunc';
import '../../../assets/css/styles.css';

const { withCRUDUtils, currencyToNumber, numberToCurrency } = UtilsFunc

export default {
  name: 'ProcedureForm',
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    currentItem: Object,
    procedures: Array,
    frequencies: Array,
    statusOptions: Array,
    ativoOptions: Array,
    loading: Boolean
  },
  data: () => withCRUDUtils({
    options: {
    locale: "pt-BR",
    prefix: "R$",
    suffix: "",
    length: 11,
    precision: 2
    },
    valorCobrancaRaw: 0, 
    valorPagamentoRaw: 0, 
    requiredField: [e => e !== undefined  && e !== null && e!== '' || 'Obrigatório'],
    nrAditivo: [  v => !!v || 'Nrº Aditivo é obrigatório' ],
     currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
  }),
  methods: {
    formatNumber(value) {
      if (typeof value === 'string') {
        value = value.replace(',', '.');
      }
      return parseFloat(value) || 0;
    },
    onBeforeSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }

      // Validar datas especiais
      if (this.currentItem.procedimento.especial) {
        const dataInicioEspecial = new Date(this.currentItem.data_inicio_especial);
        const dataFimEspecial = new Date(this.currentItem.data_fim_especial);
        const dataInicio = new Date(this.currentItem.sessao.data_inicio);
        const dataFim = new Date(this.currentItem.sessao.data_fim);

        if (dataInicioEspecial < dataInicio || dataFimEspecial > dataFim) {
          this.$toast.error("As datas de execução devem estar dentro do intervalo do orçamento.");
          return;
        }
      }
       const valorCobrancaFinal = this.formatNumber(this.procedureTotalValue);
      const frequenciaId = this.currentItem?.frequencia?.id
      const fields = {
        cobranca: Boolean(this.currentItem.cobranca),
        pagamento: Boolean(this.currentItem.pagamento),
        sessao: Number(this.currentItem.guia),
        valor_cobranca: Number(this.oldUnitPrice),
        valor_cobranca_final: valorCobrancaFinal ,
        valor_pagamento: this.currentItem.valor_pagamento,
        frequencia: frequenciaId || this.currentItem.frequencia,
        procedimento: this.currentItem.procedimento.procedimento_id,
        aditivo: Boolean(this.currentItem.aditivo),
        nr_aditivo: this.currentItem.nr_aditivo,
        data_aditivo: this.currentItem.data_aditivo,
        offline: Boolean(this.currentItem.offline),
        ativo: Boolean(this.currentItem.ativo),
        id: this.currentItem.id,
        quantidade: Number(this.currentItem.quantidade),
        data_inicio_especial: this.currentItem.data_inicio_especial ? this.currentItem.data_inicio_especial : null,
        data_fim_especial:  this.currentItem.data_fim_especial ? this.currentItem.data_fim_especial : null,
        data_especial: this.currentItem.data_fim_especial ? this.currentItem.data_fim_especial : null,
        acrescimo: currencyToNumber(this.currentItem.acrescimo),
        desconto: currencyToNumber(this.currentItem.desconto),
        recurso_glosa: Boolean(this.currentItem.recurso_glosa),
        justificativarecursoglosa: this.currentItem.justificativarecursoglosa,

      }
      // console.log(fields)
      this.onSubmit(fields)
    },
    formatTimestamp() {
      if (this.currentItem.timestamp) {
        const date = new Date(this.currentItem.timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const weekDay = date.toLocaleDateString('pt-BR', { weekday: 'long' });

        return `${day}/${month}/${year} - ${weekDay.charAt(0).toUpperCase() + weekDay.slice(1)}`;
      }
      return '';
    },
    // Função para calcular a diferença de dias
      calculateDaysDifference(startDate, endDate) {
        const date1 = new Date(startDate);
        const date2 = new Date(endDate);
        const diffTime = Math.abs(date2 - date1);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      },
      // Função para atualizar a quantidade especial
      updateSpecialQuantity() {
        if (this.currentItem.data_inicio_especial && this.currentItem.data_fim_especial) {
          if (new Date(this.currentItem.data_inicio_especial) < new Date(this.currentItem.sessao.data_inicio)) {
            this.$toast.error('Data de início da execução não pode ser anterior à data de início do orçamento.');
            this.currentItem.data_inicio_especial = this.currentItem.sessao.data_inicio;
          }
          if (new Date(this.currentItem.data_fim_especial) > new Date(this.currentItem.sessao.data_fim)) {
            this.$toast.error('Data de fim da execução não pode ser posterior à data de fim do orçamento.');
            this.currentItem.data_fim_especial = this.currentItem.sessao.data_fim;
          }
          this.currentItem.quantidade = this.calculateDaysDifference(this.currentItem.data_inicio_especial, this.currentItem.data_fim_especial);
          this.currentItem.data_especial = this.currentItem.data_fim_especial;
        }
      },
  },
  computed: {
    isTimestampValid() {
      // Retorna verdadeiro se `timestamp` existe e é uma string não-vazia.
      return this.currentItem.timestamp && typeof this.currentItem.timestamp === 'string';
    },
    procedureTotalValue() {
      const addition = currencyToNumber(this.currentItem.acrescimo) || 0
      const discount = currencyToNumber(this.currentItem.desconto) || 0
      const baseValue = currencyToNumber(this.currentItem.valor_cobranca)
      const result =
       (baseValue + (baseValue * (addition / 100)) - (baseValue * (discount / 100))) * this.currentItem.quantidade
      return numberToCurrency(result)
    }
  },
  beforeMount() {
    this.oldUnitPrice = this.currentItem.valor_cobranca
    this.currentItem.valor_cobranca = numberToCurrency(this.currentItem.valor_cobranca)
    this.currentItem.acrescimo = numberToCurrency(this.currentItem.acrescimo)
    this.currentItem.desconto = numberToCurrency(this.currentItem.desconto)
  },
  watch: {
    'currentItem.valor_cobranca': function (newValue) {
      this.valorCobrancaRaw = newValue;
    },
    'currentItem.valor_pagamento': function (newValue) {
      this.valorPagamentoRaw = newValue;
    },
    'currentItem.aditivo': function (newVal) {
      if (!newVal) {
        this.currentItem.nr_aditivo = null;
      }
    },
    'currentItem.data_inicio_especial': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (new Date(newVal) < new Date(this.currentItem.sessao.data_inicio)) {
          this.currentItem.data_inicio_especial = this.currentItem.sessao.data_inicio;
        } else {
          this.updateSpecialQuantity();
        }
      }
    },
    'currentItem.data_fim_especial': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (new Date(newVal) > new Date(this.currentItem.sessao.data_fim)) {
          this.currentItem.data_fim_especial = this.currentItem.sessao.data_fim;
        } else {
          this.updateSpecialQuantity();
        }
      }
    },
  },
}
</script>
  
<style scoped>
.center-text {
  text-align: center;
  font-size: 20px;
}
</style>

